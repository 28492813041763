<template>
  <div class="col-xl-3 col-lg-4 mb-0 userCard card">
    <Avatar size="100px" classProp="rounded-circle my-4" :activeMode="true"
      >></Avatar
    >
    <strong class="d-flex justify-content-center">{{ userName }}</strong>
    <hr />
    <ul class="nav flex-column bottom-0 start-0 mb-3">
      <li class="nav-item mx-3">
        <router-link class="nav-link p-1" to="/settings/basic">
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#clipboard-data" />
          </svg>
          Datos Básicos
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link class="nav-link p-1" to="/settings/legal">
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#info-square" />
          </svg>
          Representante Legal
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link class="nav-link p-1" to="/settings/password">
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#shield-lock" />
          </svg>
          Cambio de Contraseña
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link class="nav-link p-1" to="/settings/danger">
          <svg class="bi me-2 danger" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#x-octagon" />
          </svg>
          <span class="danger">Zona Peligrosa</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Avatar from "@/components/layout/Avatar";

export default {
  name: "UserCard",
  props: ["dafultName"],
  components: { Avatar },
  computed: {
    userName: function() {
      if (this.dafultName === undefined) {
        return localStorage.getItem("user.name");
      } else {
        return this.dafultName;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.userCard {
  background-color: var(--secondary-bg);
  height: fit-content;
}
.userCard a {
  color: var(--main-text);
}
.userCard svg {
  height: 16px;
  width: 16px;
}
.router-link-exact-active .danger {
  color: var(--bs-danger) !important;
}
</style>
