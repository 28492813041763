<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <UserCard></UserCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <Banner></Banner>
        <div class="mt-4 settingsBody rounded card">
          <div class="pt-3 pb-0 ps-4">
            <strong>Representante Legal</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendLegal">
              <div class="mb-3">
                <label for="name" class="form-label">Nombre</label>
                <input
                  type="text"
                  class="form-control"
                  name="name"
                  v-model="legalRepresentative"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="ID" class="form-label">Cédula</label>
                <input
                  type="number"
                  class="form-control"
                  name="ID"
                  v-model="representativeID"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">Correo Electrónico</label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  v-model="representativeEmail"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="mobile" class="form-label">Número Celular</label>
                <input
                  type="number"
                  class="form-control"
                  name="mobile"
                  v-model="representativeMobile"
                />
              </div>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import Banner from "@/components/layout/Banner";
import UserCard from "@/views/settings/UserCard";

import Storage from "@/modules/storage";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: { Legal, Banner, UserCard },
  name: "Settings Legal",
  data() {
    return {
      companyId: "",
      legalRepresentative: "",
      representativeID: "",
      representativeEmail: "",
      representativeMobile: ""
    };
  },
  beforeCreate() {
    localStorage.setItem("currentLocation", "Ajustes / Representante Legal");
  },
  methods: {
    sendLegal() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const URL = new Storage().baseURL + "/optima/company/" + this.companyId;
      const sendData = [
        {
          legalRepresentative: this.legalRepresentative,
          representativeID: this.representativeID,
          representativeEmail: this.representativeEmail
        }
      ];
      if (this.representativeMobile !== "") {
        sendData[0].representativeMobile = this.representativeMobile;
      } else {
        sendData[0].representativeMobile = null;
      }
      axios
        .put(URL, sendData, {
          withCredentials: true
        })
        .then(response => {
          new Storage().updateData("company", response.data.body);
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "No es posible actualizar los datos en este momento",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        });
    }
  },
  mounted() {
    const store = new Storage();
    store.getData("company").then(data => {
      this.companyId = data[0]._id;
      if (data[0].legalRepresentative !== null) {
        this.legalRepresentative = data[0].legalRepresentative;
      }
      if (data[0].representativeID !== null) {
        this.representativeID = data[0].representativeID;
      }
      if (data[0].representativeEmail !== null) {
        this.representativeEmail = data[0].representativeEmail;
      }
      if (data[0].representativeMobile !== null) {
        this.representativeMobile = data[0].representativeMobile;
      }
    });
  }
};
</script>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>
